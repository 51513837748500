import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Banner } from "../components/banner"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { mq } from "../layouts/theme"
import { useTheme } from "emotion-theming"

const P = styled.p`
  letter-spacing: 1px;
  margin-bottom: 16px;
  font-size: 12px;
  font-family: ProximaNova-Regular;
`

const Privacy = () => {
  const {
    colors: {
      bg: { lightGrey },
    },
  } = useTheme()
  const { banner, ogImg } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "privacy-banner-img.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ogImg: file(relativePath: { eq: "privacy-banner-img.png" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Privacy Policy"
        description=" At First Shelter Ventures & Realty Coporation, accessible from
            fsvrc.com, one of our main priorities is the privacy of our
            visitors. This Privacy Policy document contains types of information
            that is collected and recorded by First Shelter Ventures & Realty
            Coporation and how we use it."
        img={ogImg.childImageSharp.fixed.src}
      />
      <Banner img={banner} bgPosition={"bottom right"} />
      <section
        css={css`
          padding: 50px 20px;
          background: ${lightGrey};
          ${mq.mobile} {
            padding: 50px 40px;
          }
          ${mq.tablet} {
            padding: 50px;
          }
          flex: 1;
        `}
      >
        <div
          css={css`
            max-width: 1400px;
            margin: 0 auto;
          `}
        >
          <h2
            css={css`
              font-family: ProximaNova-Bold;
              letter-spacing: 3px;
              text-align: center;
            `}
          >
            PRIVACY POLICY
          </h2>
          <br />
          <P>
            At First Shelter Ventures & Realty Coporation, accessible from
            fsvrc.com, one of our main priorities is the privacy of our
            visitors. This Privacy Policy document contains types of information
            that is collected and recorded by First Shelter Ventures & Realty
            Coporation and how we use it.
          </P>
          <P>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </P>
          {[
            {
              t: "Log Files",
              c:
                "First Shelter Ventures & Realty Corporation follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.",
            },
            {
              t: "Cookies and Web Beacons",
              c:
                "Like any other website, First Shelter Ventures & Realty Corporation uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.",
            },
            {
              t: "Third Party Privacy Policies",
              c:
                "You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.",
            },
            {
              t: "Online Privacy Policy Only",
              c:
                "This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in First Shelter Ventures & Realty Coporation. This policy is not applicable to any information collected offline or via channels other than this website.",
            },
            {
              t: "Consent",
              c:
                "By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.",
            },
          ].map(({ t, c }) => {
            return (
              <div css={css``}>
                <h6
                  css={css`
                    margin-bottom: 0;
                    font-size: 14px;
                    font-family: ProximaNova-Bold;
                    letter-spacing: 1px;
                  `}
                >
                  {t}
                </h6>
                <P>{c}</P>
              </div>
            )
          })}
        </div>
      </section>
    </>
  )
}

export default Privacy
